import React from 'react'
import './css/Navbar.css'


const Navbar = () => {
  return (
  <>
    <div className="container-fluid navmain">
       <div className="navleft">
         <div className="logo ">
            <img src="./images1/anplogo.png" alt="" style={{width:"250px" ,}} />
         </div>
       </div> 
       <div className="navmid">
           <h1><span id='firsth'>A</span><span id='first'>&</span><span id='firsth'>P</span> <span id="firsth"> I</span><span id='first'>NTERNATIONAL</span>  <span id="firsth"> S</span><span id='first'>CHOOL</span></h1>
           
           <p style={{color:'#1a237e',fontWeight:"bold",fontSize:"15px"}}>Learning today. Leading tomorrow. Inspired Forever.</p>
       </div>
       <div className="navright">
            <div className="socialmedia">
            <a href="https://www.facebook.com/AandPInternationalSchool/" target="_blank"style={{textDecoration:"none",color:"#1a237e"}}> <i className="ri-facebook-fill"></i></a>

            <a href='https://www.instagram.com/aandp_in?igsh=YzljYTk1ODg3Zg==' target="_blank" style={{textDecoration:"none",color:"#1a237e"}}><i className="ri-instagram-line" style={{color:"#1a237e"}}></i></a>  
            <a href='' target="_blank" style={{textDecoration:"none",color:"#1a237e"}}> <i className="ri-youtube-line"> </i></a>
            </div>
            <div className="seacrch">
                <input className='aa' type="search"  style={{ border: "2px solid", borderWidth: "2px", color: "#1a237e" }} />
                <div className="btn" style={{backgroundColor:"#1a237e"}}>Search</div>
            </div>
       </div>
    </div>
  </>
  )
}

export default Navbar