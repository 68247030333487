import React from "react";
import NobleSidebar from "./NobleSidebar";
import axios from 'axios';
import useSWR from 'swr';

const Principal = () => {

  const  fetcher = async (url)=>{
    const response = await axios.get(url);

    return response.data;
  };

  const API_ENDPOINT  ="https://cms.maitretech.com/anpschool/items/principle?fields=*.*.*";

const { data, error } = useSWR(API_ENDPOINT,fetcher);

if (error) {
  return <div>Error loading data</div>;
}

if (!data) {
  return <div>Loading...</div>;
}

const directorData = data?.data[0];

  return (
    <>
      <div className="container-fluid p-0">
        

        <div className="container-fluid latest_card_box_ad p-5">

          <div className="ourschoolboardeft px-0 text-left">

            <div className="princd">
              <div className="photos">
                <img src={directorData?.principle_image?.data?.full_url} alt="principal" />
                <div className="phname">
                  <b> Principal</b> - {directorData?.principle_name}
                </div>
              </div>

              <div className="director_mess">
                <h5>
                  <b> Principal Message -</b>
                </h5>
                <p>
                {directorData?.principle_message}
                </p>
              </div>
            </div>
        
            
            <p>
              <b>Dear Parents, Students, and Visitors:</b>
            </p>
            <p>
              <i>A&P International School </i>
            </p>
            <p>
              Welcome to the A&P International School . As we embark into a
              new journey of growth and learning, we will
              be able to take our school to the next level. At our end, we shall
              continue working with you for the best interests of our beloved
              students and our community at large.
            </p>
             
            <p>
              Nothing can be more effective in motivating the students and
              sustaining success in the school than celebrating the students’
              accomplishments. Celebrating success in the school system
              regularly breeds more success. It invites every student, teacher,
              and parent to join the team to celebrate improvements in
              academics, character, and leadership.
            </p>
         
            <p>
              As the A&P International School  principal, I am more than
              willing to do all it takes to make our school an outstanding
              institution, where our students are nurtured through mind, body,
              and soul. Such a mission will not be possible to accomplish
              without the full support and cooperation of our parent body and
              community at large. Hence, should you have any suggestions or
              constructive feedback, do not hesitate to contact me by email or
              phone.
            </p>
            <p>We look forward to serving you and your children.</p>
            <p>
            </p>
            <p>
              <b>{directorData.principle_name}, Principal -</b>
            </p>
            {/* <p>A&P International School </p> */}
            <p>
            Address: 36 37, Karamveer Nagar, Sector c, Indrapuri, Bhopal, Madhya Pradesh 462022</p>
              {/* <p>Fax: +91 7772888558</p> */}
        
          </div>

          <div className="ourschoolright p-4">
            <NobleSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Principal;
