import React, { useState, useEffect } from "react";
import "./css/Gallery.css";

const Gallery = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://cms.maitretech.com/anpschool/items/gallery?fields=*.*.*"
      );
      const jsonData = await response.json();
      console.log("Fetched data:", jsonData);

      const sliderImages = jsonData?.data?.[0]?.gallery_image;
      if (sliderImages) {
        const imageUrls = sliderImages.map(
          (item) => item.directus_files_id.data.full_url
        );
        setData(imageUrls);
      } else {
        console.error("Slider images not found in response data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <div className="container-fluid">
        <div className="first">
          <div className="one">
            <img src="./images1/Home-sports.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery1.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery3.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/skilled-staff.jpeg" alt="" />
          </div>
        </div>
        <div className="first">
          <div className="one">
            <img src="./images1/gallery4.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery5.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/home3.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery6.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery6.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery6.jpeg" alt="" />
          </div>
        </div>
        <div className="first">
          <div className="one">
            <img src="./images1/home1.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/home2.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/gallery7.jpeg" alt="" />
          </div>
          <div className="one">
            <img src="./images1/home4.jpeg" alt="" />
          </div>
        </div>
      </div> */}
      <div className="first">
      {
       data.map((imageUrls,index)=>(
        <div key={index}  className="one" >
          <img src={imageUrls} alt={`Image ${index}`} /> 
          </div>
       ))
      }

      </div>
    </>
  );
};

export default Gallery;
