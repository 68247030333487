import React from "react";
import "./css/ourschool.css";
import NobleSidebar from "./NobleSidebar";

const OurSchool = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="ourschooleft px-5 text-left">
            {/* <h4 className="kalur text-center" style={{color:"black", fontWeight:"bold"}}>Our School</h4> */}
            <b>
              <p>
                <em> A&P International School</em>
              </p>
            </b>
            <b>
              <p>
                <em>
                  A&P International School was founded in 1998 as School is
                  one of the oldest schools for both girls and boys managed by
                  A&P International School Group. A&P International School Group is run by Mr.
                  Abdul Sattar khan. Our institution is recogniged by the Madhya
                  Pradesh Board of education.We, A&P International School, understand that
                  each child is a distinct individual who needs to be nurtured
                  in order to grow into a mature and responsible citizen. Our
                  academic infrastructure along with a wide range of
                  co-curricular activities help our students in the all round
                  personality development. We have a strong team of motivated
                  teachers who are always ready to accept challenges of
                  developing the potential of each and every student. Keeping in
                  view the ideas of democracy and our ancient culture, A&P International School
                   strives to provide a system of education most
                  suited to the needs of our society today. Stress on innovative
                  methods of teaching , opportunities for shouldering
                  responsibilities during school life, constant participation in
                  sports and co-curricular activities lend meaning to the school
                  life. Thus, the end product is the harmonious, all round
                  developed personality of our students poised on the threshold
                  of life.
                </em>
              </p>
            </b>
           
            <p>
              We encourage all community members to discover the possibilities
              at the A&P International School!
            </p>

            <h6>
              <b>Dice Code:- 23320403520</b>
            </h6>
            <h6>
              <b>School Code:- 632568</b>
            </h6>
            <div className="new-factitle">
              <div className="new-factitle-1">
                <div className="factitle">
                  
                  <b> Subject Group :- </b>
                </div>
                <p>Math Science, Bio-Science, Commerce & Arts.</p>
              </div>
              <div className="new-factitle-1">
                <div className="factitle">
                  
                  <b> Medium :-</b>
                </div>
                <p>English & Hindi</p>
              </div>
              <div className="new-factitle-1">
                <div className="factitle">
                  
                  <b> Scholarship :-</b>
                </div>
                <p>
                  Minrority Scholarship :- <li>Pre Matric</li>
                  <li>Post Matric</li>
                </p>
              </div>
              <div className="factitle">
                <b> RTE Admission</b>
              </div>
            </div>
          </div>

          <div className="ourschoolright p-4">
            <NobleSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurSchool;
