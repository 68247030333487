import React from "react";
import "./css/BannerS.css";
import "./css/LatestNews.css";
import "./css/Admission.css";
import "./css/HlastSection.css";
import { useEffect, useState } from "react";



const BannerSection = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch('https://cms.maitretech.com/anpschool/items/faciltes?fields=*.*.*');
      const jsonData = await response.json();

      const imageUrls = jsonData.data[0].facilites_image.map((item) => item.directus_files_id.data.full_url);

      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <>
      <div className="container-fluid p-0">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
       
          <div className="carousel-inner" role="listbox">
            { 
            data.map((imageUrls,index)=>(
              <div className="carousel-item active" key={index}>
              <img
                className="d-block slideimage"
                src={imageUrls}
                alt="First slide"
              />
              <div className="carousel-caption d-none d-md-block">
 
              </div>
            </div>
            ))}
           
        
          </div>




          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon jj"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon jj"
              aria-hidden="true"
            ></span>
            <span className="sr-only"></span>
          </a>
        </div>
      </div>
    </>
  );
};

export default BannerSection;
