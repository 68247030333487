import React from 'react'
import {NavLink} from "react-router-dom"
import './css/Footer.css'

const Footer = () => {
  return (
    <>
    <div className="container-fluid footermain p-2">
       
       <div className="footermid">
           <div className="footerbottm">
             <div className="fbleft">
                <li className='my-3'><NavLink  to="/">Home</NavLink></li>
                <li className='my-3'><NavLink  to="/boarddirector">Board of Director</NavLink></li>
                <li className='my-3'><NavLink  to="/principal">Principal</NavLink></li>
                <li className='my-3'><NavLink  to="/gallery">Gallery</NavLink></li>
             </div>
             <div className="fbright">
                <li className='my-3'><NavLink  to="/ourschool">About Us</NavLink></li>
                <li className='my-3'><NavLink  to="/academicprocedure">Academic</NavLink></li>
                <li className='my-3'><NavLink  to="/facilities">Facilities</NavLink></li>
                <li className='my-3'><NavLink  to="/contactus">Contact Us</NavLink></li>
             </div>
           </div>

           
       </div>

       <div className="footerleft">
         <div className="logo">
            <img src="./images1/anplogo.png" alt="" />
         </div>
       </div>
       <div className="fbrightt">
            <div className="socialmedia1">
           <a href="https://www.facebook.com/AandPInternationalSchool/" target="_blank"style={{textDecoration:"none",color:"#1a237e"}}> <i className="ri-facebook-fill"></i></a>
           <a href='https://www.instagram.com/aandp_in?igsh=YzljYTk1ODg3Zg==' target="_blank" style={{textDecoration:"none",color:"#1a237e"}}><i className="ri-instagram-line" style={{color:"#1a237e"}}></i></a>
           <a href='' target="_blank" style={{textDecoration:"none",color:"#1a237e"}}> <i className="ri-youtube-line"> </i></a>
           
           

            </div>
            <div className="seacrch" >
                <input  type="search" style={{ border: "2px solid", borderWidth: "2px", color: "#1a237e" }} />
                <div className="btn btn-lg" style={{backgroundColor:"#1a237e"}}>
                <i className="ri-search-line" style={{color:"#fefefe"}}></i>
                </div>
            </div>
            <div className="copyright mt-2">
              <p id='copyr' style={{textDecoration:"none",color:"#1a237e"}}>© Made with &nbsp;<i className="ri-heart-fill" style={{color:"red", fontSize:"20px"}}> </i> <a style={{color:"#1a237e", textDecoration:"none"}} target="_blank" href='https://maitretech.com/'>&nbsp; by Maitretech Solution</a></p>
            </div>
       </div>





    </div>
    
    
    <div className="fbtm" >
            <p style={{color:'#1a237e',fontWeight:"600",fontSize:"15px"}}>Address: 36 37, Karamveer Nagar, Sector c, Indrapuri, Bhopal, Madhya Pradesh 462022

         </p>
           </div>
    
    
    </>
  )
}

export default Footer